import { useContext, useEffect, useState } from "react";
import { Flex, Spacer } from "../../components/shared/global";
import { useNavigate, useParams } from "react-router-dom";
import {
  createRoom,
  deleteRoom,
  generateRoomApiKey,
  getProfessionList,
  getRoomById,
  postConfigureRoom,
} from "~/services";
import LoadingLayout from "../../components/shared/LoadingLayout";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  message,
  notification,
} from "antd";
import {
  AppstoreAddOutlined,
  CopyOutlined,
  DeleteOutlined,
  GlobalOutlined,
  KeyOutlined,
  LeftOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "~/context/global.context";
import {
  copyTextToClipboard,
  languageLabel,
  languageOptions,
  searchFilterOptions,
} from "~/utils/helpers";
import { IConfigureRoomDto, ICreateRoomDto } from "~/model/dto/room-dto";
import RoomLogoUploader from "./RoomLogoUploader";
import RoomInviteLinks from "./RoomInviteLinks";
import RoomEditionArticles from "./RoomEditionArticles";
import RoomEditionContents from "./RoomEditionContents";
import RoomEditionPlaylists from "./RoomEditionPlaylists";
import { AxiosError } from "axios";
import { IRoom } from "~/model/room.model";
import { IProfession } from "~/model/profession.model";
import { IUserRole, RoomType } from "~/model/enum";
import { fetchData } from "~/store/actions";
import { FETCH_ROOMS } from "~/store/types";
import { useDispatch } from "react-redux";

const RoomEdition = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organisationList, tagList, user } = useContext(GlobalContext);
  const [room, setRoom] = useState<IRoom | null>(null);
  const [professions, setProfessions] = useState<IProfession[] | null>(null);
  const [innerSpecialties, setInnerSpecialties] = useState<string[] | null>(
    null
  );
  const [innerApiKey, setInnerApiKey] = useState<string | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchRoomData = async () => {
      if (!params.id) return;

      const roomData = await getRoomById(params.id);

      setInnerSpecialties([
        roomData.mainSpecialty?._id,
        ...roomData.medicalSpecialties.map((spe) => spe._id),
      ]);

      setRoom(roomData);
    };

    fetchRoomData();
  }, [params.id]);

  useEffect(() => {
    const fetchProfessions = async () => {
      const { docs: professionList } = await getProfessionList({ limit: 100 });

      setProfessions(professionList);
    };

    fetchProfessions();
  }, []);

  const handleSubmit = async (values: IConfigureRoomDto | ICreateRoomDto) => {
    if (params.id) {
      // Update room
      try {
        const innerValues = values as IConfigureRoomDto;
        await postConfigureRoom(params.id, {
          ...innerValues,
          mainSpecialty: innerValues?.mainSpecialty
            ? innerValues.mainSpecialty
            : null,
        } as IConfigureRoomDto);

        message.success("Room updated successfully!");
      } catch (error) {
        message.error("An error occured while updating the room");
        throw error;
      }
    } else {
      // Create room
      try {
        const res = await createRoom(values as ICreateRoomDto);
        message.success("Room created successfully!");
        navigate(`/sensitive-data/rooms/${res.id}`, { replace: true });

        // Update reducer state
        dispatch(fetchData(FETCH_ROOMS));
      } catch (error) {
        message.error("An error occured while creating the room");
        throw error;
      }
    }
  };

  const handleChangeMainSpecialty = (value: string) => {
    setInnerSpecialties(
      innerSpecialties ? [...innerSpecialties, value] : [value]
    );

    form.setFieldsValue({
      medicalSpecialties: form
        .getFieldValue("medicalSpecialties")
        ?.filter((spe: string) => spe !== value),
      tags: [],
    });
  };

  const handleChangeMedicalSpecialties = (values: string[]) => {
    setInnerSpecialties(
      innerSpecialties
        ? [...new Set([...innerSpecialties, ...values])]
        : [...values]
    );

    form.setFieldsValue({
      tags: [],
    });
  };

  const handleGenerateApiKey = async () => {
    Modal.confirm({
      title: "Generate API key",
      content:
        "Are you sure you want to generate a new API key? If previous key is set, it will be replaced.",
      onOk: async () => {
        try {
          if (!params.id) throw new Error("No room id provided");

          const { apiKey } = await generateRoomApiKey(params.id);
          notification.success({
            message: "API key generated successfully",
          });

          setInnerApiKey(apiKey);
        } catch (error) {
          notification.error({
            message: "An error occured while generating the API key",
            description: (error as AxiosError<{ message: string }>)?.response
              ?.data?.message,
          });
          throw error;
        }
      },
    });
  };

  async function handleDeleteRoom() {
    Modal.confirm({
      title: "Are you sure you want to delete this room?",
      content: "This action is irreversible.",
      onOk: async () => {
        try {
          if (!params.id) throw new Error("No room id provided");

          await deleteRoom(params.id);
          notification.success({
            message: "Room deleted successfully",
          });

          navigate("/sensitive-data/rooms", { replace: true });
        } catch (error) {
          notification.error({
            message: "An error occured while deleting the room",
            description: (error as AxiosError<{ message: string }>)?.response
              ?.data?.message,
          });
          throw error;
        }
      },
      okText: "Yes",
      okButtonProps: {
        danger: true,
      },
    });
  }

  if (!room && params.id) return <LoadingLayout />;

  const initialRoomValues = {
    ...room,
    organisation: room?.organisation._id,
    professions: room?.professions?.map((prof) => prof._id),
    mainSpecialty: room?.mainSpecialty?._id,
    medicalSpecialties: room?.medicalSpecialties?.map((spe) => spe._id),
    tags: room?.tags?.map((tag) => tag._id),
  };

  return (
    <div className='basic-layout'>
      <Button
        type='link'
        onClick={() => navigate(-1)}
        icon={<LeftOutlined />}
        style={{ padding: 0, marginBottom: 8 }}
      >
        {"Back"}
      </Button>
      {innerApiKey && (
        <Alert
          showIcon
          type='info'
          style={{ marginBottom: 16 }}
          message={
            <span>
              {"The API key is: "}

              <b
                onClick={() => copyTextToClipboard(innerApiKey)}
                style={{ cursor: "pointer" }}
              >
                {innerApiKey}
                <CopyOutlined style={{ marginLeft: 8, fontSize: 15 }} />
              </b>
            </span>
          }
          description={
            "This key is used to authenticate your room with the API. Keep it safe, it will not be displayed again."
          }
        />
      )}
      <Spacer height={12} />
      <Flex justify='space-between' align='center'>
        <h1 style={{ margin: 0, width: "100%" }}>{`${
          room ? room.name : "Create"
        } - Room`}</h1>
        <Flex align='center' gap={8} justify='end'>
          {params.id && room?.type === RoomType.ExternalApi && (
            <Button
              icon={<KeyOutlined />}
              disabled={!user?.roles?.includes(IUserRole.SUPER_ADMIN)}
              onClick={handleGenerateApiKey}
            >
              {"Generate API key"}
            </Button>
          )}
          <Button
            type='primary'
            icon={params.id ? <SaveOutlined /> : <AppstoreAddOutlined />}
            onClick={() => form.submit()}
          >
            {params.id ? "Save settings" : "Create Room"}
          </Button>
          {params.id ? (
            <Button
              icon={<DeleteOutlined />}
              type='primary'
              danger
              onClick={handleDeleteRoom}
              style={{ display: "none" }} // TODO: Remove this line
            >
              {"Delete Room"}
            </Button>
          ) : null}
        </Flex>
      </Flex>
      <Spacer />
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={initialRoomValues}
      >
        <Tabs color='primary' type='card'>
          <Tabs.TabPane key={0} tab='Informations'>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card title='Room Informations'>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name='organisation'
                        label='Organisation'
                        required
                      >
                        <Select
                          placeholder='Select an organisation'
                          disabled={!organisationList.length || !!room}
                          options={organisationList.map((org) => ({
                            label: org.name,
                            value: org._id,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name='type' label='Room Type' required>
                        <Select
                          placeholder='Select a room type'
                          disabled={!!room}
                          options={Object.values(RoomType).map((type) => ({
                            label:
                              type === RoomType.Default ? "In-App Room" : type,
                            value: type,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name='isPublic' label='Accessibility' required>
                        <Select placeholder='Accessibility'>
                          <Select.Option value={true}>
                            {"✅ Public"}
                          </Select.Option>
                          <Select.Option value={false}>
                            {"🔐 Private"}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {room?.allowedLanguages ? (
                      <Divider orientation='left'>{"Room names"}</Divider>
                    ) : null}
                    {room?.allowedLanguages?.length
                      ? room?.allowedLanguages?.map((lang) => (
                          <Col span={12} key={lang + "room-info-name"}>
                            <Form.Item
                              name={["translations", lang]}
                              label={`Room ${languageLabel[lang]} Name`}
                              required
                            >
                              <Input
                                placeholder={`Room ${languageLabel[lang]} Name`}
                                prefix={<GlobalOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        ))
                      : null}
                    <Col span={12}>
                      <Form.Item
                        name='name'
                        label={`Room Default name`}
                        required
                      >
                        <Input
                          placeholder='Room Default Name'
                          value={room?.name}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} />

                    <Col span={12}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item name='description' label='Description'>
                            <Input.TextArea
                              autoSize={{
                                minRows: 3,
                                maxRows: 8,
                              }}
                              placeholder='Write down any description for this room'
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Flex flexDirection='column' gap={16} style={{ width: "100%" }}>
                  <RoomLogoUploader variant='ico' room={room} />
                  <RoomLogoUploader variant='logo' room={room} />
                </Flex>
              </Col>
              <Col span={12}>
                <Card title='Room Settings'>
                  <Row gutter={[16, 0]}>
                    <Col span={24}>
                      <Form.Item
                        name='allowedLanguages'
                        label='Allowed Languages'
                      >
                        <Select
                          mode='multiple'
                          placeholder='Allowed Languages'
                          options={languageOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name='professions' label='Professions'>
                        <Select
                          showSearch
                          allowClear
                          mode='multiple'
                          placeholder={"Professions"}
                          style={{ width: "100%" }}
                          filterOption={searchFilterOptions}
                          options={professions?.map((prof) => ({
                            label: prof.translations.en,
                            value: prof._id,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name='mainSpecialty' label='Main Specialty'>
                        <Select
                          showSearch
                          allowClear
                          disabled={!tagList.length}
                          placeholder={"Main specialty"}
                          style={{ width: "100%" }}
                          filterOption={searchFilterOptions}
                          onChange={handleChangeMainSpecialty}
                          options={tagList
                            .filter((el) => !el.parent)
                            ?.map((spe) => ({
                              label: spe.translations.en,
                              value: spe._id,
                            }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name='medicalSpecialties'
                        label='Medical Specialties'
                      >
                        <Select
                          showSearch
                          allowClear
                          mode='multiple'
                          disabled={!tagList.length}
                          placeholder={"Medical Specialties"}
                          style={{ width: "100%" }}
                          filterOption={searchFilterOptions}
                          onChange={handleChangeMedicalSpecialties}
                          options={tagList
                            ?.filter((el) => !el.parent)
                            ?.filter(
                              (el) =>
                                el?._id !== form.getFieldValue("mainSpecialty")
                            )
                            ?.map((spe) => ({
                              label: spe.translations.en,
                              value: spe._id,
                            }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name='tags' label='Tags'>
                        <Select
                          showSearch
                          allowClear
                          mode='multiple'
                          placeholder={"Tags"}
                          style={{ width: "100%" }}
                          filterOption={searchFilterOptions}
                          options={tagList
                            .filter((el) => !!el.parent)
                            .filter(
                              (el) =>
                                (el.parent?._id &&
                                  innerSpecialties?.includes(el.parent?._id)) ||
                                innerSpecialties?.includes(el?._id)
                            )
                            ?.map((tag) => ({
                              label: `${tag.translations.en} (${tag.parent?.translations.en})`,
                              value: tag._id,
                            }))}
                        />
                      </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={8}>
                      <Form.Item
                        name='discoveryFilter'
                        label='Discovery Filter'
                      >
                        <Select
                          placeholder='Discovery Filter'
                          options={[
                            {
                              label: "👍 Yes",
                              value: true,
                            },
                            {
                              label: "👎 No",
                              value: false,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name='primaryColor'
                        label='Primary Color'
                        tooltip='Background color in-app for DISCOVERY PAGE.'
                      >
                        <Input placeholder='#FFFFFF' />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name='secondaryColor'
                        label='Secondary Color'
                        tooltip='Background color in-app for CONGRESS PAGE.'
                      >
                        <Input placeholder='#FFFFFF' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane key={1} disabled={!room} tab='Content'>
            {room && (
              <Col span={24}>
                <Card title='Room Contents & Playlists'>
                  <Tabs>
                    <Tabs.TabPane key='articlesTab' tab='Articles'>
                      <RoomEditionArticles articles={room.articles} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='contentTabs' tab='Contents'>
                      <RoomEditionContents contents={room.contents} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='playlistsTabs' tab='Playlists'>
                      <RoomEditionPlaylists playlists={room.playlists} />
                    </Tabs.TabPane>
                  </Tabs>
                </Card>
              </Col>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key={2} tab='Invites'>
            {room && <RoomInviteLinks room={room} />}
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </div>
  );
};

export default RoomEdition;
